import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const FiverrLogoDesign = () => (
  <Layout>
    <SEO
      title="Logo Design on Fiverr: The Ultimate Guide"
      description="How to find a professional designer and get your logo on Fiverr. Avoid making mistakes with these tips. Save money, time and get the perfect logo first time"
    />
    <h1>Logo Design on Fiverr: The Ultimate Guide</h1>
    <p>
      A good logo for your business is important for many reasons. I guess you
      already knew that which is why you are here. You probably already know how
      useful outsourcing jobs can be too. It can a great time saver and lets you
      focus on the things you do best. 
    </p>
    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/GPpXNoe.jpg"
        alt="User profile on fiverr mrtranscendence campbell data science"
        description="Fiverr user profile of mrtranscendence campbell data science review and guide
"
      />
    </div>
    <p>
      Fiverr is one of the best places to get a professional logo made online
      quickly. We will go over how you can find a designer and how to get
      exactly what you want without making costly mistakes. Here’s a link to my
      overall guide on outsourcing work online with Fiverr if you need more than
      a logo.
    </p>
    <p>
      <i>
        Some of the links in this article may be affiliate links, which means I
        may receive a small commission at no additional cost to you if you
        decide to purchase something. 
      </i>
    </p>
    <h2>Looking for a logo designer</h2>
    <div style={{ textAlign: "center" }}>
    <a href="http://www.anrdoezrs.net/click-9160314-13585513" target="_blank" rel="noopener noreferrer nofollow"><img src="http://www.awltovhc.com/image-9160314-13585513" alt="728x90 Get More Done, Together" width="728" height="auto" border="0" /></a>
    </div>
    <p>
      When you{" "}
      <a
        href="http://www.kqzyfj.com/click-9160314-13101169"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        go to Fiverr
      </a>
      , there are lots of categories across the header of the page. Hover over
      Graphics & Design the click Logo Design. It might ask you if you have a
      specific design in mind. Feel free to skip this section by scrolling down.
    </p>
    <p>
      Here is where you can browse for your logo designer. Fiverr wants you to
      have a good experience, so you keep coming back and you recommend their
      services to your friends. This means they will do a lot of the hard work
      for you and put some of the best designers at the top of the list. It's
      likely that you might find the designer you want to work with without
      having to search much at all.
    </p>
    <p>
      Have a look at the previews, you might already have an idea of what type
      of logo you want already. Do any of these designers have work that align
      with what you had in mind? 
    </p>
    <p>
      Use the arrows on the preview image to scroll through more examples. A lot
      of designers will have a large single image as an example and a collage of
      examples of up to six designs. This should give you a good idea of the
      type of work they do. After all, they will display some of their best work
      to grab your attention.
    </p>
    <p>
      When you see a designer you like, you can save them to a list. You can
      either make a new list or just use the default list Fiverr gives you.
      Click the heart. If you don't have access to this feature, you will need
      to sign in. It will make things a lot easier later on when it's time to
      make a decision.
    </p>
    <p><a
      href="http://www.kqzyfj.com/click-9160314-13101169"
      target="_blank"
      rel="noopener noreferrer nofollow"
      style={{ textDecoration: "none" }}
    >
      Click here to sign up and make an account
    </a></p>
    <h2>Seller Profile Preview</h2>
    <p>
      After making note of a few designers that catch your eye. It's time to
      review. You will have noticed the star rating, the number of reviews and
      the seller level. Let’s go over each of these;
    </p>
    <h3>Star Rating</h3>
    <p>
      The rating is out of 5 and the best sellers usually have a 4.8 or above.
      You can read reviews to find out why but they usually get a high rating
      for good communication, sticking to the brief and delivering quickly.
    </p>
    <h3>Number of Reviews</h3>
    <p>
      A lot of sellers have been on the platform for a long time and have racked
      up over 1000 reviews. that's a lot of work. A 4.8 rating with over 1k
      reviews shows you that they must be doing something right. This is a great
      sign of trust. For a lot of sellers, this is probably the main source of
      their income so they take this very seriously.
    </p>
    <h3>Seller Level</h3>
    <p>
      This rating is awarded by Fiverr and gives us a bit more information about
      the seller. there are 4 seller levels. You can read about them in more
      detail on the site. For now here's an overview;
    </p>
    <h4>New Seller</h4>
    <p>
      Just joined Fiverr and created a gig. Fiverr encourages users at this
      level to increase their level by working hard and being fair.
    </p>
    <h4>Level One Seller</h4>
    <p>
      After 60 days on the platform Fiverr reviews a seller's performance. They
      must complete at least 10 individual offers and maintain a 4.7 star
      rating. Sellers must also maintain at least a 90% response rate, order
      completion and on-time delivery.
    </p>
    <h4>Level Two Seller</h4>
    <p>
      Fiverr notes that this level "isn't easy to achieve, but it's worth it".
      Sellers must maintain the same customer service as above along with a few
      other requirements for 120 days. They must also have completed 50
      individual offers.
    </p>
    <h4>Top Rated Seller</h4>
    <p>
      This is the elite group in Fiverr's own words. Sellers don't get here by
      accident and if they have this rating you can trust that they are going to
      deliver. Again, similar to the requirements at previous levels with at
      least 180 days and 100 individual offers.
    </p>
    <h2>Review Designer profile</h2>
    <p>Now it's time to click through and look at the sellers profile...</p>
    
    <div style={{ textAlign: "center" }}>
    <a href="http://www.jdoqocy.com/click-9160314-13585516" target="_blank" rel="noopener noreferrer nofollow">
<img src="http://www.ftjcfx.com/image-9160314-13585516" alt="468x60 Get More Done, Together" width="468" height="auto" border="0" /></a>
    </div>

    <h3>Packages</h3>
    <p>
      Starting with the <strong>package window</strong> to the right. Sellers
      usually have 3 pricing packages, Basic, Standard and Premium. These set
      out the number of revisions you can get, the number of days for delivery
      and any other products the seller wants to include.
    </p>
    <p>
      One piece of advice about ordering a design is that some (not many)
      sellers will try their luck and ask you to pay more than advertised. If
      this happens to you, remind them of the price they advertised and they
      will often agree to that.
    </p>
    <p>
      Just below this window is the <strong>contact seller button</strong>. Use
      this to ask the seller any questions. We will come back to what to ask for
      in a separate section below. I have put this in its own section because it
      will make all the difference for getting a logo design you are happy with.
    </p>
    <h3>The "About this Gig" section</h3>
    <p>
      This is the sellers opportunity to sell you on their service. Read through
      it and see if what they say aligns with what you want to receive. They
      will often go into why you should choose them, a bit about their taste in
      design and some detail about what you will get out of them. Professional
      designers usually have a great about section because they understand the
      importance of presenting a good image to potential customers.
    </p>
    <h3>About The Seller</h3>
    <p>
      Just below the gig section is this about section. It will tell you where
      the seller is from. This will give you an indication of their first
      language. In my experience, some sellers will put down that they are
      fluent in English but you will notice they aren't the best in their
      written communication.
    </p>
    <p>
      Use the message contact button I mentioned above to get an idea and see if
      this will get in the way. If you are dealing with a top seller, it
      shouldn’t be a problem.
    </p>
    <p>
      You will also get an idea of the response and delivery time. An example I
      am looking at right now has a 2 hour average response time and a recent
      delivery of 45 minutes. The response time is how long it takes for them to
      reply to your message. It’s in the sellers best interest to respond
      quickly because this information is on display for everyone to see. I have
      personally had someone respond to me in 2 minutes. I was surprised.
    </p>
    <p>
      The recent delivery tells you how long it could take for them to deliver
      on the job. I mentioned before about the different packages and how the
      advertised delivery time varies. Don't worry about this too much because
      sellers often under promise and over deliver in this area. Great customer
      service.
    </p>
    <h3>My Portfolio</h3>
    <p>
      This section lets you dig a bit deeper into the work of the seller. You
      can see the design examples in higher resolution that in the little
      preview window before. make a note of the things you like and don't like.
      This way when you contact the seller you can reference these things and
      they will know what you are describing.
    </p>
    <h3>Compare Packages</h3>
    <p>
      This is where you can go over what you will get in more detail if you
      order. You can see a side by side comparison in a handy table. If you
      aren't too familiar with graphic design, I will go over some of the things
      you should look out for further on. It will help you avoid mistakes.
    </p>
    <p>
      <a
        href="http://www.kqzyfj.com/click-9160314-13101169"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Find a logo designer now
      </a>
    </p>

    <h2>A note on Image type</h2>
    <p>
       I will go over this because it could save you money now but cost you
      later. Images come in 2 different types.{" "}
      <a
        href="https://www.makeovermonday.co.uk/gallery/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Vector and raster
      </a>
      , if you already know the difference skip ahead.
    </p>
    <p>
      <strong>Raster image</strong> - An image that has a set number of pixels.
      This number can be increased or decreased but quality will be affected. If
      you take a picture on your phone, this is a raster image. You will be able
      to print it at around 6 x 4 inches and it will look perfectly fine, sharp
      in fact. If you tried to blow this image up to poster size, it would
      probably look horrible.
    </p>
    <p>
      <strong>Vector image</strong> - This type of image is made up of points in
      digital space, maths. It means that you can set the original image to
      whatever size you want with no loss in quality. You can print it and 6 x 4
      inches, poster size or even billboard size and it will look perfect.
    </p>
    <p>
      I used printing as an example but pixilation of an image that has been
      enlarged on a computer screen is also noticeable.
    </p>
    <ul>
      <li>
        <strong>Raster image format examples</strong> - jpg, pdf, png
      </li>
      <li>
        <strong>Vector image formats</strong> - You should ask if not specified
        but usually vector images will be provided in the native file format.
        The program they were designed in. For example Adobe Illustrator (.ai).
        Don’t be alarmed if you cannot open the file, your designer can.
      </li>
    </ul>
    <h2>Why do you want the native file?</h2>
    <p>
      I have already mentioned the benefits of resizing. Another benefit is ff
      you want to make any edits to your logo in the future, it will be much
      easier if you have the original file. You can pick up where your designer
      left off.
    </p>
    
    <div style={{ textAlign: "center" }}>
    <a href="http://www.anrdoezrs.net/click-9160314-13585513" target="_blank" rel="noopener noreferrer nofollow">
<img src="http://www.awltovhc.com/image-9160314-13585513" alt="728x90 Get More Done, Together" width="728" height="auto" border="0" /></a>
    </div>

    <h2>What to ask for from a logo designer (your brief & checklist)</h2>
    <p>
      I want my readers to have the best experience on Fiverr so I'll give you a
      sort of template of what you ask for when writing to your first designer.
      After all, it wouldn't be good if you had a bad experience on a platform I
      believe in and recommended to you.
    </p>
    <h3>Brand Name & Tagline</h3>
    <p>
      The brand name is pretty straight forward. Don’t forget to give the
      designer your tag line if you have one. It will give the designer
      something to keep in mind during the design process. A brand name might
      not mean anything to a new customer, it might not even be a real word. A
      good tag line can’t be misunderstood, it should give your customers an
      insight into what you are about. 
    </p>
    <h3>Target audience and demographic</h3>
    <p>
      Who is your brand aimed at? If you have customers already, you may already
      know this. If not, make a note of who your ideal customer is. You'll be
      surprised how a difference in target audience can affect the logo design.
      A good designer will be able to use this information to influence the
      final logo.
    </p>
    <h3>Colour Psychology</h3>
    <p>
      There is an argument to say this is nonsense but look at some major brands
      and make up your own mind about whether the theory aligns or not. You
      might already have brand colours in mind, if you don’t here is an outline
      of the{" "}
      <a
        href="https://en.wikipedia.org/wiki/Color_psychology"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        psychology of colours;
      </a>
    </p>

    <ul>
      <li>
        Red - Attracts the human eye. Suggests Energy, Danger and Passion.
      </li>
      <li>Green - The restful colour. Suggests Nature, Safety and Harmony</li>
      <li>Blue - Office colour. Suggests Depth, Quality and Truth</li>
      <li>Yellow - The happy colour. Suggests Light, Warmth and Positivity</li>
      <li>Black - Authority. Suggests Power, Formality and Elegance</li>
      <li>
        Grey - Conservative. Suggests Security, Reliability and Professionalism
      </li>
      <li>White - Purity. Suggests Neutrality and Goodness</li>
    </ul>
    <h3>Display size</h3>
    <p>
      Think about where you want to display your logo. A logo that can be on the
      tab of an internet browser and one that can be printed above your building
      entrance could look very different. A smaller logo can’t afford to have
      lots of little details because they will get lost and make the logo look
      messy. You might need to consider getting two versions of the logo made.
      One for small applications and one for large.
    </p>
    <h3>Ask for in the following formats...</h3>
    <p>
      Professional designers will be able to provide your logo in the following
      formats with no problem. We went over the importance of a vector image
      file so you know why you should get one, they provide edibility and
      scalability. You should get a combination of both vector and raster. The
      raster image files will let you apply them in your business quickly. You
      will be familiar with some already;
    </p>
    <ul>
      <li>PNG transparent & Opaque - [raster]</li>
      <li>pdf - [raster]</li>
      <li>jpg - [raster]</li>
      <li>Adobe illustrator - [vector]</li>
    </ul>
    <p>
      <strong>Note</strong> - Graphic designers usually use the adobe
      illustrator software. This is not the only design software available, the
      idea is the get the native file format. The original file of the program
      the designer used to create your logo.
    </p>
    <p>
      <strong>Note</strong> - A transparent png is an image with a transparent
      background. This means you can overlay images without the background
      blocking other graphics or text. The part of the image that would usually
      just be a white box around your logo is not there.
    </p>
    <h3>Examples of work</h3>
    <p>
      Yes, you can send your designer example logos. This is often forgotten, it
      can help a lot. You might already have some sketches or example logos,
      even logos saved of other companies. Send each of these to your designer
      for inspiration. This is probably one of the best things you can do so
      take advantage of this feature.
    </p>
    
    <div style={{ textAlign: "center" }}>
    <a href="http://www.jdoqocy.com/click-9160314-13633836" target="_blank" rel="noopener noreferrer nofollow">
<img src="http://www.awltovhc.com/image-9160314-13633836" alt="640x100 Get A Custom Logo" width="640" height="auto" border="0" /></a>
    </div>

    <h2>Not happy with your design?</h2>
    <p>
      As good as the designers are on Fiverr, there is a chance you might not
      like the design. If you follow the advice I gave here, you should be
      completely happy. In the rare chance that you are not, here are some
      things you can do;
    </p>
    <p>
      <strong>Use your revisions</strong> - I mentioned design revisions earlier
      on. Use these to your advantage. Look at the first design you receive
      carefully and make a note of everything you can think of. Good and bad.
      Then think about what you would like to see changed and send these notes
      to your designer. The next revision should be much closer to what you
      want.
    </p>
    <p>
      <strong>Reject the delivery</strong> – Click “not yet” instead of “happy”
      and this will give you the opportunity to communicate with the seller. You
      can go over the things you want resolved before you accept.
    </p>
    <p>
      <strong>Dispute Resolution</strong> – You can open a dispute and request
      the seller cancels the order. This way work can stop and you don’t have to
      pay for anything.
    </p>
    <p>
      <strong>Pay anyway</strong> – This might sound a bit strange but if you
      pay for the logo and hold onto it. When you approach the next designer you
      can use it as an example of what not to do. There might be some things you
      like about it and the next designer could have the skills to make it
      perfect for you.
    </p>
    <p>
      <strong>Don't be afraid to negotiate</strong> - Remember that the
      designers on Fiverr and real people often with real businesses. Not robots
      on the internet. It’s likely that they will do what it takes to keep you
      happy. Their rating depends on it. Basically, if you see want anything
      they haven’t mentioned, just ask for it. See if they will include it for
      free or at a small fee.
    </p>
    <p>
      <a
        href="http://www.kqzyfj.com/click-9160314-13101169"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Get started on Fiverr, Click here
      </a>
    </p>
    <h2>Conclusion</h2>
    <p>
      Remember that outsourcing is a great opportunity to get quality
      professional work done quickly. Life can get really busy and there is only
      so much time in the day. Focus on the tasks that you are good at and
      outsource the rest if it makes sense financially.
    </p>
    <p>
      With regards to logo design, I’ve tried. I’m happy coding websites,
      building algorithms, even running marketing campaigns. When I try logo
      design, I can come up with okay concepts and designs but I am always
      disappointed in the execution. It also takes a very, very long time. I’ve
      decided that it’s just one of those things I’ll leave to the
      professionals. Some of you might be in a similar position to me.
    </p>
    <p>
      <a
        href="http://www.kqzyfj.com/click-9160314-13101169"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Hire a designer and get your first logo on Fiverr
      </a>
    </p>
    <p>
      In this guide we have only looked at logo design. Follow the link ahead to
      my article on outsourcing work online with Fiverr for some inspiration.
      There are so many categories for hiring freelancers it’s amazing. Here’s a
      list of the categories I go over;
    </p>
    <ul>
      <li>Graphics & Design</li>
      <li>Digital Marketing</li>
      <li>Writing & Translation</li>
      <li>Video & Animation</li>
      <li>Music & Audio</li>
      <li>Programming & Tech</li>
      <li>Business</li>
    </ul>

    <p>
      I still haven’t been through them all myself and I’m excited to find out
      what else is on there.
    </p>
    <h2>Is Fiverr legit?</h2>
    <p>
      I have to add this because one of the things people ask first when
      spending money online is; "am I going to get scammed?". Everyone is right
      to ask this because it's quite easy to appear legitimate online.
    </p>
    <p>
      Yes, Fiverr is legit. The site has a reputation to keep so it does lots to
      make sure you get the best service. One of the main things it does to
      protect buyers is let you pay only when you are happy with the
      service. You don't get this kind of benefit when hiring a local
      freelancer, it's standard in most industries to pay a fraction of the
      project upfront to insure the freelancer.
    </p>
    <p>
      If you still have doubts, there are plenty of videos on youtube from
      reputable channels who have loyal fans who would spot a fake review from a
      mile away.
    </p>
    <p>
      <i>
        Some of the links in this article may be affiliate links, which means I
        may receive a small commission at no additional cost to you if you
        decide to purchase something.
      </i>
    </p>

 

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default FiverrLogoDesign
